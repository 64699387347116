import React, { useEffect, useState } from "react";
import socketService from "../../services/socket-service";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import AudioRecorder, { AudioChannelType } from "./audio-recorder-component";
import { Row, Avatar, List, FloatButton, Typography, Layout } from "antd";
import { GlobalOutlined } from "@ant-design/icons";
const { Text } = Typography;

const languages = ["it-IT", "en-US", "de-DE", "es-ES", "fr-FR"];
const sentenceTerminators = [".", "!", "?"];

const SingleSpeaker: React.FC = () => {
  const navigate = useNavigate();
  const [sentences, setSentences] = useState<string[]>([]);
  const [recognitionLanguage, setRecognitionLanguage] = useState<string>("it-IT");
  const [demoDuration, setDemoDuration] = useState<number>(0);
  const [demoDurationInterval, setDemoDurationInterval] = useState<NodeJS.Timeout | null>(null);
  const [payloadSent, setPayloadSent] = useState<number>(0);

  const login = async () => {
    console.warn("Logging in!");
    // try to get the access token
    const loginCookie = Cookies.get("home4MeAiLoginToken");
    const loginData = JSON.parse(loginCookie || "{}");
    const accessToken = await socketService.getAccessToken(loginData.clientId, loginData.clientSecret);
    if (accessToken) {
      setLoggedIn(true);
      socketService.bindRecognitionEvents(`${process.env.REACT_APP_WS_URL}`, accessToken, setRecognizedWords, setRecognitionDuration);
    } else {
      setLoggedIn(false);
      console.error("Empty access token");
      Cookies.set("home4MeAiLoginToken", JSON.stringify({ loginError: "Cannot login, try again!" }));
      navigate("/login");
    }
  };

  useEffect(() => {
    login();
  }, []);

  const [recognizedWords, setRecognizedWords] = useState<string[]>([]);
  const [recognitionDuration, setRecognitionDuration] = useState<number | null>(0);
  const [demoStarted, setDemoStarted] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);

  const onSpeechStarted = () => {
    console.log("Got event audio-recording-started");
    setSentences([]);
    setRecognizedWords([]);
    setRecognitionDuration(0);
    setDemoDuration(0);
    setDemoStarted(true);
    if (demoDurationInterval) {
      clearInterval(demoDurationInterval);
    }
    setDemoDurationInterval(
      setInterval(() => {
        setDemoDuration((prevDuration) => prevDuration + 1);
      }, 1000)
    );
  };

  const stopDemo = () => {
    setDemoStarted(false);
    setPayloadSent(0);
    if (demoDurationInterval) {
      clearInterval(demoDurationInterval);
      setDemoDurationInterval(null);
    }
  };

  const onSpeechEnded = () => {
    stopDemo();
  };

  // Detect when recognizedWords changes
  useEffect(() => {
    if (demoStarted && recognizedWords && recognizedWords.length > 0) {
      console.log("Recognized words:" + recognizedWords);
      if (!sentences.length) {
        let sentence = recognizedWords.join(" ");
        setSentences([sentence]);
      } else {
        let parsedSentences = recognizedWords.join(" ").split(new RegExp(`(?<=[${sentenceTerminators.join("")}])`));
        console.log("Parsed sentences:\n" + parsedSentences.join("\n"));
        setSentences([...parsedSentences]);
      }
      console.log("Sentences:" + sentences.length);
    }
  }, [recognizedWords]);

  const formatBytes = (bytes: number, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  return loggedIn ? (
    <div>
      <Row justify="center" align="middle" style={{ padding: 20 }}>
        <AudioRecorder
          language={recognitionLanguage}
          sampleRate={16000}
          encoding="Float32Array"
          channel={AudioChannelType.Mono}
          timeSlice={200}
          speechStarted={onSpeechStarted}
          speechEnded={onSpeechEnded}
          speechSent={(dataSize) => setPayloadSent((prevSize) => prevSize + dataSize)}
        />
      </Row>
      <List
        itemLayout="horizontal"
        dataSource={sentences}
        locale={{ emptyText: "Waiting for your beautiful voice :-)" }}
        renderItem={(item, index) => (
          <List.Item>
            <Row justify="start" align="middle">
              <Avatar src={`https://api.dicebear.com/7.x/pixel-art/svg`} />
              <span style={{ paddingLeft: 5, maxWidth: "70%" }}>{item}</span>
            </Row>
          </List.Item>
        )}
      />
      {recognitionDuration && demoStarted ? (
        <div style={{ color: "green", marginLeft: 30, paddingTop: 20 }}>
          <span style={{ color: "green" }}>(durata: {(recognitionDuration / 1000).toFixed(0)} secondi)</span>
        </div>
      ) : null}
      <FloatButton.Group
        shape="square"
        trigger="click"
        type="primary"
        icon={<GlobalOutlined />}
        closeIcon={false}
        description={recognitionLanguage.split("-")[1]}
      >
        {languages
          .filter((l) => l != recognitionLanguage)
          .map((lang) => (
            <FloatButton
              key={lang}
              onClick={() => setRecognitionLanguage(lang)}
              icon={<GlobalOutlined />}
              description={lang.split("-")[1]}
            />
          ))}
      </FloatButton.Group>
      <Row justify="center" align="middle">
        <Text code>
          <span>Lang: {recognitionLanguage.split("-")[1]}</span>
          <span style={{ marginLeft: 15 }}>
            Elapsed <b>{demoDuration}</b> sec
          </span>
          <span style={{ marginLeft: 15 }}>
            Sent <b>{formatBytes(payloadSent)}</b>
          </span>
        </Text>
      </Row>
    </div>
  ) : null;
};

export default SingleSpeaker;
