import React from "react";
import { useSpring, animated } from "react-spring";
import Logo from "../../assets/logo";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const BouncingLogoPage: React.FC = () => {
  const navigate = useNavigate();

  const props = useSpring({
    from: { transform: "translate3d(0, -100vh, 0)" },
    to: [
      { transform: "translate3d(0, 0, 0) scale(1)" },
      { transform: "translate3d(0, 0, 0) scale(0.8)" }, // Zoom out
      { transform: "translate3d(0, 0, 0) scale(1)" }, // Zoom in
    ],
    config: { duration: 500 }, // 2 seconds divided by 3 keyframes
    reset: true,
    onRest: () => {
      setTimeout(() => {
        const loginCookie = Cookies.get("home4MeAiLoginToken");
        if(!loginCookie) 
          navigate("/login");
        else
          navigate("/transcription/single-speaker"); // Navigate to the recognizer page after the animation
      }, 2000);
    },
  });

  return (
    <animated.div style={props}>
      <Logo />
    </animated.div>
  );
};

export default BouncingLogoPage;
