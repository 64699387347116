import { Socket, io } from "socket.io-client";

export class RecognitionResult {
  stt: STTResult;
  speaker: SpeakerResult;
  constructor() {
    this.stt = new STTResult();
    this.speaker = new SpeakerResult();
  }
}

export class STTResult {
  text: string;
  confidence: number;
  constructor() {
    this.text = "";
    this.confidence = 0;
  }
}

export class SpeakerResult {
  name: string;
  constructor() {
    this.name = "";
  }
}

var socket: Socket;
const bindRecognitionEvents = (
  url: string,
  accessToken: string,
  onResult: (result: string[]) => void,
  onEnd: (duration: number) => void
) => {
  if (accessToken) {
    if (socket) {
      console.warn("WebSocket already exists, skip the initialization");
      return;
    }

    socket = io(url, {
      path: process.env.REACT_APP_WS_PATH,
      extraHeaders: {
        Authorization: `Bearer ${accessToken}`,
      },
      withCredentials: false,
    });

    socket.on("recognition-result", (result: RecognitionResult) => {
      if (result?.stt?.text) {
        let recognizedWords = result.stt.text.split(" ");
        onResult(recognizedWords);
      }
    });

    socket.on("streaming-recognition-result", (result: RecognitionResult) => {
      if (result?.stt?.text) {
        let recognizedWords = result.stt.text.split(" ");
        onEnd(0);
        onResult(recognizedWords);
        console.log("Recognized words: " + recognizedWords);
      } else {
        console.log("No recognized words");
      }
    });

    socket.on("streaming-recognition-ended", (result: any) => {
      console.log("Streaming recognition end: duration is " + result.duration);
      onEnd(result.duration);
    });

    return () => {
      socket.disconnect();
    };
  } else {
    console.error("No access token");
  }
};

const sendSpeechStart = (recognitionLanguage: string, sampleRateHerts: number, audioChannelsCount: number, encoding: string) => {
  console.warn("Send speech start");
  socket.emit("speech-start", {
    audioFormat: { sampleRateHerts, audioChannelsCount, encoding },
    message: { languageToRecognize: recognitionLanguage, streamingRecognitionEnabled: true },
  });
};

const sendSpeechData = async (data: Blob | Float32Array) => {
  console.warn("Send speech data of type " + data.constructor.name);
  if (data instanceof Float32Array) {
    if (data.length > 0) {
      socket.emit("speech-data", { audioFormat: { encoding: "Float32Array" }, message: data });
    } else {
      console.log("No data available");
    }
  } else {
    const base64data = await blobToBase64(data);
    if (base64data.length > 0) {
      socket.emit("speech-data", { message: base64data });
    } else {
      console.log("No data available");
    }
  }
};

const sendSpeechEnd = () => {
  console.warn("Send speech end");
  socket.emit("speech-end", {});
};

function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64 = (reader.result as string).split(",")[1];
      //console.log("Base64 data: " + base64);
      resolve(base64);
    };
    reader.readAsDataURL(blob);
  });
}

// Create a function to renew or ask the Access Token
const getAccessToken = async (clientID: string, clientSecret: string) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_SECURE_SERVER_URL}/connect/token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        grant_type: `client_credentials`,
        client_id: clientID,
        client_secret: clientSecret,
        scope: "home4me.api",
      }),
    });

    if (!response.ok) {
      console.error(`HTTP error! status: ${response.status}`);
      return null;
    }

    const data = await response.json();
    return data.access_token;
  } catch (err) {
    console.error("Error getting the Access Token: " + err);
    return null;
  }
};

export default { bindRecognitionEvents, getAccessToken, sendSpeechStart, sendSpeechData, sendSpeechEnd };
