// create a component for a beautiful login
import React, { useEffect, useState } from "react";
import { Form, Input, Button, Checkbox, Row, Image } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { Alert } from "antd";

import logo from "../../assets/logo.svg";

const LoginPage = () => {
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState("");

  useEffect(() => {
    const loginCookie = Cookies.get("home4MeAiLoginToken");
    const loginData = JSON.parse(loginCookie || "{}");
    if (loginData.loginError) {
      setLoginError(loginData.loginError);
    }
    Cookies.remove("home4MeAiLoginToken");
  });

  const onFinish = (values: any) => {
    console.log("Received values of form: ", values);
    if (!values.clientId || !values.clientSecret) {
      return;
    }

    Cookies.set(
      "home4MeAiLoginToken",
      JSON.stringify({
        clientId: values.clientId,
        clientSecret: values.clientSecret,
      })
    );

    navigate("/transcription/single-speaker");
  };

  return (
    <Row justify="center" align="middle">
      {loginError?.length ? (
        <Alert
          message={loginError}
          type="warning"
          style={{ padding: 10 }}
          action={
            <Button size="small" ghost type="primary" onClick={() => setLoginError("")} style={{ marginLeft: 10 }}>
              Login agin
            </Button>
          }
          showIcon
        />
      ) : (
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          style={{ backgroundColor: "transparent" }}
          onFinish={onFinish}
        >
          <Form.Item
            name="clientId"
            rules={[
              {
                required: true,
                message: "Client ID is required!",
              },
            ]}
          >
            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Client ID" />
          </Form.Item>
          <Form.Item
            name="clientSecret"
            rules={[
              {
                required: true,
                message: "The client secret is required!",
              },
            ]}
          >
            <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Client secret" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button">
              Log in
            </Button>
          </Form.Item>
        </Form>
      )}
    </Row>
  );
};

export default LoginPage;
