import { AudioOutlined } from "@ant-design/icons";
import { TinyColor } from "@ctrl/tinycolor";
import { Button, ConfigProvider, Row, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import socketService from "../../services/socket-service";
import RecordRTC from "recordrtc";

export enum AudioChannelType {
  Mono = 1,
  Stereo = 2,
}
interface AudioRecorderProps {
  sampleRate: number;
  encoding: string;
  channel: AudioChannelType;
  timeSlice: number;
  language: string;
  speechStarted: () => void;
  speechEnded: () => void;
  speechSent: (chunkSize: number) => void;
}

const AudioRecorder: React.FC<AudioRecorderProps> = (props: AudioRecorderProps) => {
  const [recordStarted, setRecordStarted] = useState(false);
  const buttonColors = ["#fc6076", "#ff9a44", "#ef9d43", "#e75516"];
  const getHoverColors = (colors: string[]) => colors.map((color) => new TinyColor(color).lighten(5).toString());
  const getActiveColors = (colors: string[]) => colors.map((color) => new TinyColor(color).darken(5).toString());
  const [recorder, setRecorder] = useState<RecordRTC | ScriptProcessorNode>();

  const createRecorder = () => {
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
      })
      .then((stream: MediaStream) => {
        if (props.encoding === "LINEAR16") {
          const recorder = new RecordRTC(stream, {
            type: "audio",
            mimeType: "audio/wav",
            sampleRate: props.sampleRate,
            desiredSampRate: props.sampleRate,
            numberOfAudioChannels: props.channel == AudioChannelType.Mono ? 1 : 2,
            bufferSize: 4096,
            //audioBitsPerSecond: props.sampleRate * (props.encoding === "LINEAR16" ? 16 : 32),
            timeSlice: props.timeSlice,
            recorderType: RecordRTC.StereoAudioRecorder,
            ondataavailable: (data: Blob) => {
              if (data.size > 0) {
                //console.log("Chunk data: " + data.size);
                props.speechSent(data.size);
                socketService.sendSpeechData(data);
              } else {
                console.log("No data available");
              }
            },
          });

          setRecorder(recorder);
          recorder.startRecording();
        } else {
          // Create a new MediaRecorder instance
          console.warn("MediaRecorder is not supported. Using ScriptProcessorNode instead.");
          let audioContext = new AudioContext({ sampleRate: props.sampleRate, latencyHint: "interactive" });
          let mediaStream = audioContext.createMediaStreamSource(stream);
          let recorder = audioContext.createScriptProcessor(4096, 1, 1);

          recorder.onaudioprocess = async (event) => {
            if (!audioContext) return;

            const inputData = event.inputBuffer.getChannelData(0);

            if (inputData.length > 0) {
              //console.log("Chunk data: " + data.size);
              props.speechSent(inputData.length / 2);
              socketService.sendSpeechData(inputData);
            } else {
              console.log("No data available");
            }
          };

          setRecorder(recorder);
          // Prevent page mute
          mediaStream.connect(recorder);
          recorder.connect(audioContext.destination);
        }
      })
      .catch((err) => {
        console.log("Error: " + err);
      });
  };

  const startRecording = () => {
    console.log("-- startRecording");

    createRecorder();
    setRecordStarted(true);
    socketService.sendSpeechStart(props.language, props.sampleRate, props.channel === AudioChannelType.Mono ? 1 : 2, props.encoding);
    console.log("Recording started");

    props.speechStarted();
  };

  const stopRecording = () => {
    console.log("-- stopRecording");

    if (recorder instanceof RecordRTC) {
      recorder?.stopRecording();
    } else {
      (recorder as ScriptProcessorNode).disconnect();
    }

    setRecordStarted(false);
    socketService.sendSpeechEnd();

    props.speechEnded();
  };

  // return a component using Ant design to start and stop the recording
  return (
    <Row justify="center" align="middle">
      <ConfigProvider
        theme={
          recordStarted
            ? {
                components: {
                  Button: {
                    colorPrimary: `linear-gradient(90deg,  ${buttonColors.join(", ")})`,
                    colorPrimaryHover: `linear-gradient(90deg, ${getHoverColors(buttonColors).join(", ")})`,
                    colorPrimaryActive: `linear-gradient(90deg, ${getActiveColors(buttonColors).join(", ")})`,
                    lineWidth: 0,
                  },
                },
              }
            : {}
        }
      >
        <Button
          icon={<AudioOutlined />}
          color={recordStarted ? "red" : "green"}
          type="primary"
          onClick={() => (recordStarted ? stopRecording() : startRecording())}
        >
          {recordStarted ? "Stop Recording" : "Start Recording"}
        </Button>
      </ConfigProvider>
      <Spin size="small" spinning={recordStarted} style={{ marginLeft: 20 }}>
        <div className="content" />
      </Spin>
    </Row>
  );
};

export default AudioRecorder;
