import "./App.css";
import BouncingLogoPage from "./pages/home/bouncing-logo-page";
import { Route, Routes } from "react-router";
import SingleSpeaker from "./pages/transcription/recognition-page";
import NotFound from "./pages/default/not-found-page";
import { BrowserRouter } from "react-router-dom";
import LoginPage from "./pages/login/login-page";
import "antd/dist/reset.css";
import logo from "./assets/logo.svg";
import { Layout, Typography } from "antd";
import { useEffect, useState } from "react";
const { Header, Content, Footer } = Layout;
const { Text } = Typography;

const App: React.FC = () => {
  const [logoWidth, setLogoWidth] = useState("20%");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const Root = () => {
    return (
      <div>
        <Routes>
          <Route path="/" element={<BouncingLogoPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="transcription/single-speaker" element={<SingleSpeaker />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    );
  };

  useEffect(() => {
    if (windowWidth <= 768) {
      setLogoWidth("25%");
    } else {
      setLogoWidth("6%");
    }
  }, [windowWidth]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Layout style={{ height: "100vh", width: "100vw" }}>
      <Header style={{ display: "flex", alignItems: "center" }}>
        <img src={logo} style={{ width: logoWidth }} />
        <Text style={{ color: "white", fontSize: 20, paddingLeft: 20 }}>Hear4Me demo</Text>
      </Header>
      <Content style={{ marginTop: 15 }}>
        <BrowserRouter>
          <Root />
        </BrowserRouter>
      </Content>
    </Layout>
  );
};

export default App;
