import React, { useEffect, useState } from "react";
import logo from "./logo.svg";

const Logo: React.FC = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const logoWidth = windowWidth <= 768 ? "60%" : "20%"; // If the window width is 768px or less, set the width to 60%, otherwise 20%

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <img src={logo} alt="Logo" style={{ width: logoWidth }} />
    </div>
  );
};

export default Logo;
